import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { ExtraOptions, Router, RouterModule, ROUTES, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { AppComponent } from './app.component';
import { MainLayout } from './_shared/layouts/mainLayout.component';
import { NavMenuComponent } from './_shared/nav-menu/nav-menu.component';
import { HomeComponent } from './corporate/home.component';
import { IsAuthenticatedGuard } from './_guards/isAuthenticated.gaurd';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AuthenticationService } from './_services/authentication.service';
import { LoginPageComponent } from './login/loginPage.component';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { ClickOutsideModule } from 'ng-click-outside';
import { SettingsProvider } from './_services/settingsProvider.service';
import { NavigationService } from './_services/navigation-service';
import { ToasterService } from './_services/toaster.service';
import { ToastrModule } from 'ngx-toastr';
import { UpcomingEventsListComponent } from './events/upcoming/upcoming-events-list-component.component';
import { CourseDetailsComponent } from './courses/course-details.component';
import { EventService, UserService, InstructorService, SuperUserService, CallForSponsorsService, StaticDataService, CourseService, AssessmentService, LiveEventService, OrganizationService, PosterService, ExhibitService, PollService, EventVideoService, LobbyService, CallForPapersService, AdminCallForPapersService, AdminOrganizationSettingsService, StreamBoxService, AdminConferenceSettingsService, ConferenceSchedulerService, BadgeDesignerService, SponsorshipsService, FileService, AdminMembershipService, ConferenceCMSService, ConferenceMessagingService, ConferenceSurveyService, OrganizationCMSService, CEUService, ConferenceExpenseTrackingService, ConferenceTaskService, AdManagerService, JobsService } from './_services/generatedServices';
import { EventDetailsComponent } from './events/details/event-details.component';
import { RegistrationModalComponent } from './events/registration/registration-modal.component';
import { IsSuperUserGuard } from './_guards/isSuperUser.guard';
import { SuperUserComponent } from './superUser/super-user.component';
import { CourseListComponent } from './courses/courseList/course-list.component';
import { ViewInstructorComponent } from './instructors/view-instructor.component';
import { UnpurchasedCourseComponent } from './courses/unpurchased-course/unpurchased-course.component';
import { PurchasedCourseComponent } from './courses/purchased-course/purchased-course.component';
import { MyAccountContainerComponent } from './my-account/my-account-container.component';
import { DropdownHoverDirective } from './_directives/dropdown-hover.directive';
import { ConfirmModalComponent } from './_controls/confirm-modal/confirm-modal.component';
import { ConfirmEmailComponent } from './login/confirm-email.component';
import { PrivacyPolicyComponent } from './legal/privacy-policy.component';
import { TermsOfServiceComponent } from './legal/terms-of-service.component';
import { CompletePasswordResetComponent } from './login/complete-password-reset.component';
import { BlobStorageService } from './_services/blob-storage.service';
import { BLOB_STORAGE_TOKEN, IAzureStorage } from './_services/azureStorage';
import { OrganizationContainerComponent } from './organization/organization-container.component';
import { CompanyMemberGuard } from './_guards/company-member.gaurd';
import { MsOAuthCompleteComponent } from './login/ms-o-auth-complete.component';

import { OpentokService } from './_services/opentok.service';
//import { RaygunErrorHandler } from './_helpers/app.raygun.setup';
import { CompleteInstructorInviteComponent } from './login/complete-instructor-invite.component';
import { CameraTestModalComponent } from './events/live/camera-test/camera-test-modal.component';
import { SetupChecklistComponent } from './events/live/setup/setup-checklist.component';
import { CoursePurchaseButtonDirective } from './_directives/course-purchase-button.directive';
import { SlideControlComponent } from './slide-control/slide-control.component';
import { LoginFromEmailComponent } from './login/login-from-email.component';
import { OnDemandSearchService, OrganizationSearchService } from './_services/on-demand-search-service';
import { UnsupportedBrowserComponent } from './events/live/unsupported/unsupported-browser.component';
//import { OrganizationAdminContainerComponent } from './organization/admin/organization-admin-container.component';
import { CompanyAdminGuard } from './_guards/company-admin.gaurd';
import { ApplicationPipesModule } from './_shared/modules/application-pipes.module';
import { ConferenceListingComponent } from './conferences/conference-listing.component';
import { ConferenceItemComponent } from './_shared/conference-item/conference-item.component';
import { RouterOutletContainerComponent } from './_shared/router-outlet-container.component';
import { AnalyticsService } from './_services/analytics.service';
import { OrganizationAdminWrapperComponent } from './organization/admin/organization-admin-wrapper.component';
import { CustomPreloadingStrategyService } from './_services/custom-preloading-strategy.service';
import { ProductsServicesComponent } from './corporate/products-services.component';
import { NotfoundComponent } from './_shared/notfound.component';
import { OrganizationClaimsService } from './_services/organization-claims.service';
import { ValidatorHelper } from './_helpers/validationHelper';
import { SponsorshipCartService } from './_services/sponsorship-cart.service';
import { CarouselModule } from 'primeng/carousel';
import { WibaHofComponent } from './wiba-hof/wiba-hof.component';
import { HofHomeComponent } from './wiba-hof/hof-home.component';
import { HofAboutComponent } from './wiba-hof/hof-about.component';
import { HofInducteesComponent } from './wiba-hof/hof-inductees.component';
import { PaperSearchService } from './_services/paper-search.service';
import { InducteeTitleComponent } from './wiba-hof/inductee-title.component';
import { InducteeWrapperComponent } from './wiba-hof/inductee-wrapper.component';
import { HofMainWrapperComponent } from './wiba-hof/hof-main-wrapper.component';
import { HofTimelineComponent } from './wiba-hof/hof-timeline.component';
import { HoFTimeoutService } from './_services/hof-timeout.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GoogleTranslateService } from './_services/googleTranslate.service';
import { BlobErrorHttpInterceptor } from './_helpers/blob-error.interceptior';
import { SubdomainRootComponent } from './subdomain/subdomain-root.component';
// import { PackagePurchaseModalComponent } from './conferences/registration/package-purchase-modal.component';
import { ComboBoxModule } from '@progress/kendo-angular-dropdowns';
import { MobileTestComponent } from './mobile-test.component';
import { SpreadsheetImportWizardComponent } from './_shared/spreadsheet-import-wizard.component';
import { ContractorSuccessComponent } from './corporate/contractor-success.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ZendeskMobileComponent } from './corporate/zendesk-mobile.component';
import { CaptionsDisplayComponent } from "./events/captions-display.component";
import { JobSearchComponent } from "./jobs/jobSearch.component";
import { JobSearchFilterComponent } from "./jobs/job-search-filter/job-search-filter.component";
import { JobSearchService } from "./_services/job-search.service";
import { JobDetailsComponent } from "./jobs/job-details.component";
import { AngularResizeEventModule } from "angular-resize-event";
import { ViewJobComponent } from "./jobs/viewJob.component";
import { PublicJobsContainerComponent } from "./jobs/publicJobsContainer.component";
import { JobNotFoundComponent } from "./jobs/jobNotFound.component";
import { ViewCompanyComponent } from "./jobs/viewCompany.component";
import { ConferenceEventSearchService } from "./_services/conference-event-search.service";
import { IdleService } from "./_services/idle.service";
import { CheckinService } from "./_services/checkin.service";


export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
  onSameUrlNavigation: 'reload',
  relativeLinkResolution: 'legacy',
  preloadingStrategy: CustomPreloadingStrategyService,
};

const defaultRoutes: Routes =
[
  { path: ':conferenceSlug/cc', component: CaptionsDisplayComponent, data:{title:'Caption Display'} },
  { path: 'slide-control', component: SlideControlComponent },
  { path: 'zendesk-mobile', component: ZendeskMobileComponent },
  { path: 'test', component: MobileTestComponent },
  {
    path: 'wiba/hof',
    component: HofMainWrapperComponent,
    data: { animationState: 'Twelve' },
    children: [
      {
        path: '',
        component: WibaHofComponent,
        children: [
          { path: '', component: HofHomeComponent, data: { animationState: 'One' } },
          { path: 'about', component: HofAboutComponent, data: { animationState: 'Two' } },
          { path: 'timeline', component: HofTimelineComponent, data: { animationState: 'Three' } },
          { path: 'inductees', component: HofInducteesComponent, data: { animationState: 'Four' } },
          // { path: 'inductees/:id', component: InducteeTitleComponent },
        ],
      },
      {
        path: 'inductees/:id',
        data: { animationState: 'Eleven' },
        component: InducteeWrapperComponent,

        children: [
          { path: '', redirectTo: 'home', pathMatch: 'full', data: { animationState: 'Five' } },
          { path: 'home', component: InducteeTitleComponent, data: { animationState: 'Six' } },
          { path: 'contributions', component: InducteeTitleComponent, data: { animationState: 'Seven' } },
          { path: 'milestones', component: InducteeTitleComponent, data: { animationState: 'Eight' } },
          { path: 'legacy', component: InducteeTitleComponent, data: { animationState: 'Nine' } },
          { path: 'publications', component: InducteeTitleComponent, data: { animationState: 'Ten' } },
        ],
      },
    ],
  },
  {
    path: '',
    component: MainLayout,
    children: [
      { path: '', component: HomeComponent, data: { title: 'BehaviorLive - Home' } },
      { path: 'products-services', component: ProductsServicesComponent, data: { title: 'Products & Services' } },
      { path: 'setup', component: SetupChecklistComponent, data: { title: 'Tech Check' } },
      { path: 'unsupported-browser', component: UnsupportedBrowserComponent, data: { title: 'Unsupported Browser' } },
      { path: 'login', component: LoginPageComponent, data: { title: 'Account Login' } },
      { path: 'confirmEmail', component: ConfirmEmailComponent, data: { title: 'Confirm Email' } },
      { path: 'privacy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' } },
      { path: 'terms', component: TermsOfServiceComponent, data: { title: 'Terms of Service' } },
      { path: 'msoauth/complete', component: MsOAuthCompleteComponent, data: { title: 'Complete Login' } },
      { path: 'complete-reset', component: CompletePasswordResetComponent, data: { title: 'Complete Password Reset' } },
      { path: 'complete-invite', component: CompleteInstructorInviteComponent, data: { title: 'Complete Instructor Invite' } },
      { path: 'login-from-email', component: LoginFromEmailComponent },
      { path: 'events', component: UpcomingEventsListComponent, data: { title: 'Upcoming Events' } },
      { path: 'conferences', component: ConferenceListingComponent, data: { title: 'Conferences' } },
      { path: 'events/:id', component: EventDetailsComponent, data: { isConference: false } },
      { path: 'course-library', component: CourseListComponent, data: { title: 'Course Library' } },
      { path: 'course-library/:tag', component: CourseListComponent, data: { title: 'Course Library' } },
      { path: 'courses/:id', component: CourseDetailsComponent },
      { path: 'instructors/:id', component: ViewInstructorComponent },
      { path: 'contractor-success', component: ContractorSuccessComponent },
      { path: 'jobnotfound', component: JobNotFoundComponent },
      { path: 'companies/:companyId', component: ViewCompanyComponent },
      { path: 'jobs', component: PublicJobsContainerComponent,
        children: [
          { path: '', redirectTo: 'search', pathMatch: 'full'},
          { path: 'search', component: JobSearchComponent },
          { path: 'search/:jobId', component: ViewJobComponent, data: {from_search: true} },
          { path: ':jobId', component: ViewJobComponent, data: {from_search: false} },
          { path: ':jobId/confirmApply', component: ViewJobComponent, data: {from_search: false, confirmApply: true} }
        ]
      },

      { path: 'notfound', component: NotfoundComponent },

      { path: 'account', data: { preload: true }, component: MyAccountContainerComponent, canActivate: [IsAuthenticatedGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./my-account.module').then((m) => m.MyAccountModule) },


      // { path: 'organizations/:organizationId', data: { preload: true }, component: OrganizationContainerComponent, canActivate: [CompanyMemberGuard], loadChildren: () => import('./organization.module').then((m) => m.OrganizationModule) },
    ],
  },
  { path: 'super-user', data: { preload: false }, component: SuperUserComponent, canActivate: [IsSuperUserGuard], runGuardsAndResolvers: 'always', loadChildren: () => import('./super-user.module').then((m) => m.SuperUserModule) },

  { path: 'organizations/:organizationId/admin', data: { preload: true, loadAfterSeconds: 5 }, component: OrganizationAdminWrapperComponent, canActivate: [CompanyAdminGuard], loadChildren: () => import('./organization-admin.module').then((m) => m.OrganizationAdminModule) },

  { path: 'sponsorship-portal/:sponsorOrganizationId', data: { preload: true, loadAfterSeconds: 5 }, component: OrganizationAdminWrapperComponent, canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./sponsorship-portal/sponsorship-portal.module').then((m) => m.SponsorshipPotalModule) },

  { path: 'on-demand/:id', data: { preload: true }, component: RouterOutletContainerComponent, loadChildren: () => import('./on-demand.module').then((m) => m.OnDemandModule) },

  { path: 'live-events', data: { preload: true }, component: RouterOutletContainerComponent, canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./live-events.module').then((m) => m.LiveEventsModule) },

  { path: 'lobby', data: { preload: true }, component: RouterOutletContainerComponent, canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./lobby.module').then((m) => m.LobbyModule) },

  { path: 'poster-session', data: { preload: true }, component: RouterOutletContainerComponent, canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./posters.module').then((m) => m.PostersModule) },

  { path: 'exhibitor-booth', data: { preload: true }, component: RouterOutletContainerComponent, canActivate: [IsAuthenticatedGuard], loadChildren: () => import('./exhibits.module').then((m) => m.ExhibitsModule) },

  { path: 'organizations/:organizationId', data: { preload: true }, component: OrganizationAdminWrapperComponent, loadChildren: () => import('./organization-cms.module').then((m) => m.OrganizationCMSModule) },

  { path: 'conferences/:conferenceSlug', data: { preload: true }, component: OrganizationAdminWrapperComponent, loadChildren: () => import('./conference-cms.module').then((m) => m.ConferenceCMSModule) },

  { path: 'wiba', redirectTo: 'wiba2024' },

  { path: ':conferenceSlug', data: { preload: true }, component: OrganizationAdminWrapperComponent, loadChildren: () => import('./conference-cms.module').then((m) => m.ConferenceCMSModule) },


];

declare var AzureStorage: IAzureStorage;

@NgModule({
  declarations: [
    AppComponent,
    MainLayout,
    NavMenuComponent,
    SuperUserComponent,
    HomeComponent,
    MyAccountContainerComponent,
    RegistrationModalComponent,
    CourseListComponent,
    MobileTestComponent,
    PrivacyPolicyComponent,
    TermsOfServiceComponent,
    ZendeskMobileComponent,
    UnpurchasedCourseComponent,
    DropdownHoverDirective,
    CoursePurchaseButtonDirective,
    PurchasedCourseComponent,
    JobSearchFilterComponent,
    SlideControlComponent,
    ViewCompanyComponent,
    SetupChecklistComponent,
    SpreadsheetImportWizardComponent,
    SubdomainRootComponent,
    ConfirmEmailComponent,
    OrganizationContainerComponent,
    LoginPageComponent,
    CompletePasswordResetComponent,
    ConfirmModalComponent,
    MsOAuthCompleteComponent,
    CompleteInstructorInviteComponent,
    UpcomingEventsListComponent,
    CourseDetailsComponent,
    CameraTestModalComponent,
    HofTimelineComponent,
    ContractorSuccessComponent,
    RouterOutletContainerComponent,
    //PackagePurchaseModalComponent,
    //OrganizationAdminContainerComponent,
    UnsupportedBrowserComponent,
    ConferenceListingComponent,
    JobSearchComponent,
    JobDetailsComponent,
    LoginFromEmailComponent,
    NotfoundComponent,
    HofMainWrapperComponent,
    OrganizationAdminWrapperComponent,
    ProductsServicesComponent,
    WibaHofComponent,
    HofHomeComponent,
    InducteeTitleComponent,
    HofAboutComponent,
    InducteeWrapperComponent,
    HofInducteesComponent,
    ViewJobComponent,
    PublicJobsContainerComponent,
    JobNotFoundComponent,

  ],
  entryComponents: [RegistrationModalComponent, ConfirmModalComponent, CameraTestModalComponent],
  imports: [

    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      },isolate : false
    }),
    ApplicationPipesModule.forRoot(),
    CarouselModule,
    ClickOutsideModule,
    SkeletonModule,
    DialogsModule,
    ComboBoxModule,

    NgbModule,
    ToastrModule.forRoot(),

    Angular2PromiseButtonModule.forRoot(),
    RouterModule.forRoot(
      [],
      routingConfiguration
    ),
  ],
  providers: [
    AuthenticationService,
    IsAuthenticatedGuard,
    IsSuperUserGuard,
    CompanyMemberGuard,
    CompanyAdminGuard,
    AnalyticsService,
    OrganizationService,
    CheckinService,
    ConferenceCMSService,
    PosterService,
    InstructorService,
    NavigationService,
    CallForPapersService,
    OnDemandSearchService,
    ExhibitService,
    OrganizationClaimsService,
    LiveEventService,
    StaticDataService,
    AdminConferenceSettingsService,
    CourseService,
    AssessmentService,
    SuperUserService,
    JobSearchService,
    StreamBoxService,
    ConferenceSurveyService,
    OrganizationSearchService,
    SettingsProvider,
    LobbyService,
    ConferenceTaskService,
    BadgeDesignerService,
    HoFTimeoutService,
    AdminCallForPapersService,
    EventVideoService,
    ValidatorHelper,
    OpentokService,
    FileService,
    SponsorshipsService,
    AdminOrganizationSettingsService,
    CallForSponsorsService,
    AdminMembershipService,
    PollService,
    SponsorshipCartService,
    NgbActiveModal,
    ConferenceSchedulerService,
    BlobStorageService,
    GoogleTranslateService,
    ConferenceExpenseTrackingService,
    CEUService,
    OrganizationCMSService,
    PaperSearchService,
    ConferenceMessagingService,
    AdManagerService,
    JobsService,
    JobSearchService,
    IdleService,
    ConferenceEventSearchService,

    // {
    //   provide: ErrorHandler,
    //   useClass: RaygunErrorHandler
    // },
    {
      provide: BLOB_STORAGE_TOKEN,
      useValue: AzureStorage.Blob,
    },
    UserService,
    ToasterService,
    EventService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: init, deps: [SettingsProvider, HttpClient], multi: true },
    { provide: ROUTES, useFactory:routeFactory, multi:true}
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function init(settingsProvider: SettingsProvider, http: HttpClient) {
  return () => settingsProvider.loadConfig(http);
}
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function routeFactory() {
  const subdomainSplit = window.location.hostname.split('.');
  const subdomain = subdomainSplit[0];
  console.log("subdomainSplit: " + subdomainSplit); // "my.company"
  console.log("Subdomain: " + subdomain); // "my.company"
  console.log("Host: " + window.location.hostname); // "my.company.com"

  if (subdomain == 'cc'){
    let routes: Routes = [
      { path: ':conferenceSlug', component: CaptionsDisplayComponent, data:{title:'Caption Display'} },
    ];

    return routes;
  }else {
    return defaultRoutes;
  }

}
