import { Injectable } from '@angular/core';
import { OrganizationService } from "./generatedServices";
import { ConferenceRegistrantViewModel, ConferenceViewModel, OrganizationViewModel } from "../_models/generatedModels";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class CheckinService {
  public user: ConferenceRegistrantViewModel;
  public conference: ConferenceViewModel;
  public organization: OrganizationViewModel;
  public mode: 'checkin' | 'rekey';

  constructor(private orgService: OrganizationService, private route: ActivatedRoute, private router: Router) {

    // if (this.user == null) {
    //   var url = this.router.url.replace('/preview','');
    //   console.log(url);
    //   this.router.navigateByUrl(url);
    // }
  }


  reset() {
    this.user = null;
    this.conference = null;
    this.organization = null;
  }


}
